const FUN_FACTS = [
  "I build (and break) things 🔨",
  "Level 87 pigeon enthusiast 🐦",
  "Lefties are the besties ⬅️✋",
  "Time magazine's person of the year 2006 📰",
  "Material girl in a material world 💅",
  "Cool and quirky and not like the other girls ✨",
  "Be not afraid 👁️",
  "🐱😺😸😹😻😼😽🙀😿😾",
  "💃 Macarena 🎵 sound 🎶 effect 🕺",
  "Turning bad ideas into software since 2018 💻",
  "Just because it's chemistry ‼️ doesn't mean it's drugs ‼️ 🙄 💁",
];

export default FUN_FACTS;
